<template>
	<div class="list3">
		<div v-if="listData && listData.length>0" class="list">
			<div class="list_list">
				<div v-for="(item, index) in listData" :key="index" class="list_item">
					<div class="list_div" @click="goItem(item)" :style="'background-image: url('+item.listImg+');'">
						<div class="list_title">{{item.listTitle}}</div>
					</div>
				</div>
			</div>
			<template v-if="from && from=='list'">
				<div class="pagination only_pc">
					<el-pagination @current-change="changePage" background layout="prev, pager, next" :page-size.sync="pageSize" :current-page.sync="page" :total="total"></el-pagination>
				</div>
				<div class="pagination only_mob">
					<el-pagination @current-change="changePage" small background layout="prev, pager, next" :page-size.sync="pageSize" :current-page.sync="page" :total="total"></el-pagination>
				</div>
			</template>
		</div>
		<div v-else-if="!loading" class="list_empty">
			<span>暂无数据</span>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'List',
		props: ['data', 'pageNum', 'pageSize', 'total', 'loading', 'from'],
		data() {
			var listData=this.data || []
			listData.forEach(item=>{
				if(!item.listImg || item.listImg.length<=0)item.listImg='/images/empty_image.png'
			})
			return {
				page: this.pageNum || 1,
				listData: listData
			}
		},
		methods: {
			goItem(item) {
				if(this.from && this.from == 'list') {
					item.listLink = '/page/' + item.id
				}
				if(item.listLink && item.listLink.length > 0) {
					this.$router.push({
						path: item.listLink
					})
				}
			},
			changePage(num) {
				this.$parent.changePage(num)
			}
		}
	}
</script>
<style lang="scss" scoped>
	.list3 {
		background: #393939;
		min-height: 100px;
		padding-top: 33px;
		padding-bottom: 33px;
		@media (max-width: $mob-screen) {
			padding-top: 16px;
			padding-bottom: 16px
		}
		.list {
			@include body_block;
			.list_list {
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				flex-wrap: wrap;
				margin-left: -9px;
				margin-right: -9px;
				@media (max-width: $mob-screen) {
					margin-left: -4px;
					margin-right: -4px;
				}
				.list_item {
					width: 14%;
					padding: 9px;
					.list_div {
						@include pic_bg_cover;
						cursor: pointer;
						height: 124px;
						position: relative;
						.list_title {
							@include single_line_ellipsis;
							position: absolute;
							left: 0;
							right: 0;
							bottom: 0;
							padding: 18px 12px 5px 12px;
							line-height: 17px;
							height: 40px;
							font-size: 12px;
							background-image: linear-gradient(0deg, #191a1f 0%, rgba(255, 255, 255, 0) 100%);
							color: #fff;
						}
						.list_desc {
							@include single_line_ellipsis;
							margin-top: 7px;
							line-height: 24px;
							height: 24px;
							font-size: 20px;
							color: #7a7a7a;
							@media (max-width: $mob-screen) {
								margin-top: 0;
								line-height: 20px;
								height: 20px;
								font-size: 14px;
							}
						}
					}
					@media (max-width: 1400px) {
						width: 16%;
					}
					@media (max-width: 1300px) {
						width: 20%;
					}
					@media (max-width: 1200px) {
						width: 25%;
					}
					@media (max-width: 900px) {
						width: 33%;
					}
					@media (max-width: $mob-screen) {
						width: 50%;
						padding: 4px;
					}
				}
			}
			.pagination {
				margin-top: 16px;
				text-align: right;
				@media (max-width: $mob-screen) {
					text-align: center;
				}
			}
			.only_pc {
				display: block;
				@media (max-width: $mob-screen) {
					display: none;
				}
			}
			.only_mob {
				display: none;
				@media (max-width: $mob-screen) {
					display: block;
				}
			}
		}
		.list_empty {
			color: rgba(255, 255, 255, 0.6);
			text-align: center;
			font-size: 18px;
			@media (max-width: $mob-screen) {
				font-size: 14px;
			}
		}
	}
</style>